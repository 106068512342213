<template>
  <div>
    <div>
      <div class="mainTitle">我的消息</div>
      <div class="content">
        我的消息提供我的待办、已办信息的管理，用户可在我的消息中查阅自己的待办事项和已办
        事项。
      </div>
      <img src="@/assets/productcore/oa1.png" alt="" />
    </div>
        <div>
      <div class="mainTitle">公文拟制</div>
      <div class="content">
      便捷拟制/模板化/快速查找<br/>
文件模板/专业分类/规范文件/统一管理。
      </div>
      <img src="@/assets/productcore/oa2.png" alt="" />
    </div>
            <div>
      <div class="mainTitle">工作安排</div>
      <div class="content">
    发布单位进行工作安排的发布;发布时可上传相关附件文档。
      </div>
      <img src="@/assets/productcore/oa3.png" alt="" />
    </div>
                <div>
      <div class="mainTitle">电子签章</div>
      <div class="content">
    用于特殊公文中的签批，提供电子签章管理、用印申请、在线盖章。
      </div>
      <img src="@/assets/productcore/oa4.png" alt="" />
    </div>
                    <div>
      <div class="mainTitle">数据填报</div>
      <div class="content">
    用于特殊时期人员情况统计，例如疫情期去向、调研活动等。
      </div>
      <img src="@/assets/productcore/oa5.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "work-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>